import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Preparació Entrevista Policial (1:30 h - 2024)</Title>
                <Text>
                    Us presentem la preparació per a l'entrevista policial.
                    <br />
                    <br />
                    Si estàs en algun procés de policies locals, i vols preparar-te de la millor
                    forma aquesta preparació és per tu.
                    <br />
                    <br />
                    A iOpos Acadèmia, comptem amb una metodologia pròpia de preparació d'entrevistes
                    que ens brinda excel·lents resultats any rere any.
                    <br />
                    <br />
                    La nostra preparació es fonamenta en l'entrevista oficial i disposem dels
                    millors preparadors.
                    <br />
                    <br />
                    La duració de la preparació és d'una hora i 30 minuts, de manera totalment
                    personalitzada.
                    <br />
                    <br />
                    Cada alumne és analitat i no existeixen preguntes estàndard.
                    <br />
                    <br />
                    Preparem de manera natural, ajustant-nos a la seva pròpia personalitat i forma
                    de ser.
                    <br />
                    <br />
                    En els darrers anys, hem mantingut el millor percentatge d'aprovats, incloent-hi
                    els números 1.
                    <br />
                    <br />
                    Us oferim una preparació amb nosaltres on impartirem la següent formació:
                    <br />
                    <br />
                    - Anàlisi de l'aspirant
                    <br />
                    <br />
                    - Estructura del CV
                    <br />
                    <br />
                    - Possibles preguntes oficials.
                    <br />
                    <br />
                    - Aspectes a destacar i autoconeixement personal.
                    <br />
                    <br />
                    - Casos pràctics oficials
                    <br />
                    <br />
                    - Motivacions dels canvis laborals i objectius aconseguits.
                    <br />
                    <br />
                    - Tancament de l'entrevista.
                    <br />
                    <br />
                    A més, és imprescindible que porteu el vostre CV, que serà la vostra carta de
                    presentació.
                    <br />
                    <br />
                    Novetat!!! Si realitzeu preparació i simulacre us donarem accés a un campus en
                    línia amb els casos pràctics oficials de les últimes convocatòries amb
                    explicacions.
                    <br />
                    <br />
                    Un any més, la nostra preparació és encara més personalitzada.
                </Text>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/GJJIVXsQGGo"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}

                <div id="disponibles"></div>

                <div className="mt-10 border-t border-b border-gray-300 pt-5 pb-10">
                    {/*<h1 className="font-bold uppercase font-montserrat text-xl text-cpurple">
                        Entrevistes disponibles per Formador
                    </h1>*/}

                    <div className="grid grid-cols-1 gap-10 mt-10">
                        {/** Txema */}
                        {/*<div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Txema (Agent CME) - C/ Martí Codolar,
                                18 - Hospitalet de Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat">
                                Si no veus correctament el calenderi fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-jose-m/preparacio-entrevista-per-gub-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-jose-m/preparacio-entrevista-per-gub-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>*/}

                        {/** Maite */}
                        {/*<div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Maite - C/ Martí Codolar, 18 -
                                Hospitalet de Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat">
                                Si no ves correctamente el calendario haz clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-maite/preparacio-entrevista-per-gub-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-maite/preparacio-entrevista-per-gub-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>*/}

                        {/** Toni */}
                        {/*<div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Toni - C/ Martí Codolar, 18 -
                                Hospitalet de Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat">
                                Si no veus correctament el calenderi fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-toni/preparacio-entrevista-per-gub-1-30-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-toni/preparacio-entrevista-per-gub-1-30-h?hide_gdpr_banner=1"
                            />
                        </div>*/}
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
